import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logoimg from "../../../assets/logo.png";
import Sidebarimg from "../../../assets/sidebarimg.png";

const RegisterSidebar = () => {
  const location = useLocation();

  const type = () => {
    if (location.pathname === "/" || location.pathname === "/signup") {
      return <h2> Sign Up </h2>;
    } else {
      return <h2> Back </h2>;
    }
  };

  return (
    <div className="dashboardsidemain_new">
      <img className="sidebar_img_new" src={Sidebarimg} alt="" />
      <div className="dashboardheader">
        <Link to="/">
          <img src={Logoimg} alt="" />
        </Link>
        <h4>Breevy</h4>
      </div>
      <div className="centerheadertextdashboard">
        <div className="sidebarContent">
          <h1>Welcome</h1>
          <h2>{type()}</h2>
        </div>
      </div>
    </div>
  );
};

export default RegisterSidebar;
