import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";

import { LuSearch } from "react-icons/lu";
import Divider from "@mui/material/Divider";
import DirectionsIcon from "@mui/icons-material/Directions";
import Side from "../assets/side.png";
import React, { useState} from "react";
import useStore from "../Store";
import CreateProjectModal from "./projects/CreateProjectModal";
import {Link} from "react-router-dom";
import "./custom.css"


const Drawer = () => {
    const { DrawerOpener ,storeProjects} = useStore();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const [searchQuery, setSearchQuery] = useState("");

    const filteredProjects = storeProjects.filter((project) =>
        project.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleModalOpener = () =>{
        setIsModalOpen(true);
    }
    return (
        <div>
            <div className={`drawer ${DrawerOpener ? "open" : ""}`}>
              {storeProjects && storeProjects?.length >=1 ?
                <div className="drawer-content">
                <div className="display_draw">
                    <h4>My Projects</h4>
                    <div className="drawer_add_project">
                        <AddRoundedIcon className="drawer_add_project_icon" onClick={handleModalOpener}/>
                    </div>
                </div>
                <hr
                    style={{
                        backgroundColor: "rgb(229, 229, 229)",
                        border: "none",
                        height: "1px"
                    }}
                />

                <Paper
                    component="form"
                    sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        width: 400,
                       
                    }}
                    className="no-box-shadow"
                >
                    <InputBase
                        sx={{ml: 1, flex: 1, fontWeight:500}}
                        className="drawer_search_input"
                        placeholder="Search a project"
                        inputProps={{"aria-label": "Search a project"}}
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <IconButton type="button" sx={{p: "10px",color:"#E5E5E5"}} aria-label="search">
                    <LuSearch />
                    </IconButton>
                    <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                    <IconButton
                        color="primary"
                        sx={{p: "10px"}}
                        aria-label="directions"
                    >
                        <DirectionsIcon/>
                    </IconButton>
                </Paper>
                <hr
                    style={{
                        backgroundColor: "rgb(229, 229, 229)",
                        border: "none",
                        height: "1px"
                    }}
                />
                <div style={{overflowY: "auto", maxHeight: "650px"}}>
                    {filteredProjects && filteredProjects.length > 0 ? (
                        filteredProjects.map((project) => (
                            <Link key={project.id} to={`/project/${project.id}`} >
                                <div key={project.id} className="display_draw_inner" >
                                    <img
                                    className="addicon"
                                    src={project.logo_url || Side}  // More concise way of writing it
                                    alt={`${project.name} project icon`}
                                    />
                                    <h4>{project.name}</h4>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <div className="no-projects-message">
                            <p>No projects available.</p>
                        </div>
                    )}
                </div>
            </div> 
            :
            <div className="drawer-content2">
            <h4>My Projects</h4>
            <p>
              There are no projects yet, Get started by creating a new project
            </p>
            <button
              className="create_project"
            
              onClick={handleModalOpener}
        
            >
              Create a New Project
            </button>
          </div>}
            </div>
            <CreateProjectModal open={isModalOpen}
                                setIsModalOpen={setIsModalOpen} />
        </div>
    )
};
export default Drawer;