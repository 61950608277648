import React from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AuthFooter = () => {
  const location = useLocation();
  
  const handleResendEmail = async () => {
    const email = localStorage.getItem("forgot-password-email");
    if (!email) {
        toast.error("No email found. Please try resetting your password again.");
        return;
    }

    try {
        await axios.post("/api/forgot-password/", { email });
        toast.success("Reset password email has been sent. Please check your inbox.");
    } catch (err) {
        console.error("Error resending email:", err);
        toast.error("Failed to resend email. Please try again.");
    }
  };

  const renderFooterContent = () => {
    if (location.pathname === "/" || location.pathname === "/signup" ) {
      return (
          <>
            <h3 className="auth_footer_h3">
              By Registering, You Agree To Our &nbsp;
              <Link className="color_blue" to="/terms-page">
                Service terms
              </Link>
            </h3>
            <h3>
              Already have an account? &nbsp;
              <Link className="color_blue" to="/login">
                Log in
              </Link>
            </h3>
          </>
      );
    } else if (
        location.pathname === "/login" ||
        location.pathname === "/login-email"
    ) {
      return (
          <>
            <h3 className="auth_footer_h3">
              By Registering, You Agree To Our &nbsp;
              <Link className="color_blue" to="/terms-page">
                Service terms
              </Link>
            </h3>
            <h3>
              Don't Have An Account?{" "}
              <Link className="color_blue" to="/">
                SIGNUP
              </Link>
            </h3>
          </>
      );
    } else if (location.pathname === "/forgot-password") {
      return (
          <h3 className="auth_footer_h3">
            Didn’t Get An Email?{" "}
            <Link
              to="/#."
              className="color_blue"
              onClick={(e) => {
                e.preventDefault(); // Prevents navigation
                handleResendEmail();
              }}
            >
              RESEND
            </Link>
          </h3>
      );
    } else {
      return null; // or any other default content
    }
  };

  return <div className="privacycontainer">{renderFooterContent()}</div>;
};

export default AuthFooter;
