import React, { useState } from "react";
import logo from "../assets/logo.png";
import "../components/terms.css";
import { Link } from "react-router-dom";

const TermsPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // TODO: Hamza: Need to update the content of this page.
  const terms = [
    {
      title: "Terms Of Use",
      content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }
    ,
    { title: "Terms And Conditions", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." }
    ,
    { title: "Cookie Policy", content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." }
  ];

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="container">
      <header>
        <div className="flexHead">
          <Link to="/"> <img src={logo} alt="Logo" /> </Link>
          <div className="textBrevy">Breevy</div>
        </div>
      </header>
      <div className="mainSection">
        <div className="leftSideBar">
          {terms.map((term, index) => (
            <div
              key={index}
              className={`termUse ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleClick(index)}
            >
              {term.title}
            </div>
          ))}
        </div>
        <div className="rightSideBar">
          <div className="uses">{terms[activeIndex].title}</div>
          <div className="use-1h">1. Lorem ipsum dolor sit amet, consectetur adipiscing</div>
          <div className="use-1p">
            {terms[activeIndex].content.split('\n\n').map((para, index) => (
              <div key={index}>{para}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;