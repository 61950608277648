import React, { useEffect, useState } from "react";
import { Input, Modal, Typography } from "antd";
import { Button } from "@mui/material";
import "../Documents/createDocument.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select2 from 'react-select';
import { toast } from "react-toastify";
import api from "../../utils/api";
import KeywordModal from "./KeywordModal";
const CreateDocumentModal = ({ open, setDocModalOpen }) => {
    const { id } = useParams();

    const [, setDocName] = useState("newDocument");
    const [keyword, setKeyword] = useState("");
    const [documentType, setDocumentType] = useState(null);
    const [tone, setTone] = useState(null);
    const [language, setLanguage] = useState("english");
    const [documentBrief, setDocumentBrief] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const [openKeword, setOpenKeyword] = useState(false)

    const [keywordsOptions, setKeywordsOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([])
    const [docTypeOptions, setDocTypeOptions] = useState([])
    const [toneOptions, setToneOptions] = useState([])
    const [loader, setLoader] = useState(false)


    const { Text } = Typography;
    const navigate = useNavigate();


    const mapToneOptions = (tones) => {
        return tones.map(tone => ({
            value: tone.tone_value.toLowerCase(),
            label: tone.tone_value,
        }));
    };

    const mapKeywordsOptions = (keywords) => {
        return keywords.map(keyword => ({
            value: keyword.keyword.toLowerCase(),
            label: keyword.keyword,
            kd: keyword.kd,
            volume: keyword.volume
        }));
    };

    const mapLanguageOptions = (languages) => {
        return languages.map(language => ({
            value: language.name.toLowerCase(),
            label: language.name,
        }));
    };

    const mapDocTypeOptions = (docTypes) => {
        return docTypes.map(docType => ({
            value: docType.type.toLowerCase(),
            label: docType.type,
        }));
    };

    useEffect(() => {

        const getData = async () => {
            try {
                const response = await api.get(
                    `/project-data/${id}`,
                );

                setToneOptions(mapToneOptions(response.data.tones));
                setKeywordsOptions(mapKeywordsOptions(response.data.keywords));
                setLanguageOptions(mapLanguageOptions(response.data.languages));
                setDocTypeOptions(mapDocTypeOptions(response.data.document_types));
                setDocName('newDocument')

            } catch (err) {
                console.error("fetching data failed:", err);
            }
        }
        getData();
    }, []);


    const handleOpenKeyword = () => {
        setOpenKeyword(true)
        setDocModalOpen(false)
    }
    const handleOk = async () => {
        setLoader(true)
        try {
            const response = await api.post(
                "/documents/",
                { project: id, main_keyword: keyword, doc_type: documentType.value, tone: tone.value, language: language.value, doc_brief: documentBrief },


            );

            toast.success("Document created successfully");
            handleCancel()
            navigate(`/document/${response.data.id}/`);
            setLoader(false)

        } catch (err) {
            
            console.error("Document creation failed:", err);
            toast.error(`${err?.response?.data?.main_keyword[0]}`);
            setLoader(false)
        }
    };

    const handleCancel = () => {
        setDocModalOpen(false);
        setDocName('')
        setDocumentBrief('')
        setDocumentType('')
        setTone('')
        setLanguage('')
        setKeyword("")

    };

    const handleSelectChange = (e) => {
        setKeyword(e.target.value);
    };

    useEffect(() => {
        if (keyword && documentType && tone && language) {
            setIsButtonDisabled(false);
            setButtonColor('black');
        } else {
            setIsButtonDisabled(true);
            setButtonColor('grey');
        }
    }, [keyword, documentType, tone, language, documentBrief]);
    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={loader ? 180 : 788}
                centered
                closable={!loader}
                footer={null}
                className="modal-padding"
            >
                {loader ? (
                    <div className="centered-loader">
                        <div className="loader">
                            <div className="circle-loader">
                                {[...Array(8)].map((_, index) => (
                                    <span key={index} className="dot"></span>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="modal-content">
                            <Typography.Title level={2} className="heading">Create a new document</Typography.Title>
                            <p className="p-text">The keyword you choose defines the main topic of the content you are going to create and the SERP will be based on it.</p>
                            <Text strong>*Keyword</Text>
                            <div>
                                <Input
                                    onChange={handleSelectChange}
                                    placeholder="Your keyword here..."
                                    className="keyword"
                                    value={keyword}


                                />
                            </div>
                            <p>Not sure which keyword to choose? <Link to="" className="link_tag" onClick={handleOpenKeyword}>CHECK OUT OUR KEYWORD FINDER.</Link></p>
                            <div className="select_div">
                                <Select2
                                    value={language}
                                    onChange={setLanguage}
                                    placeholder="Select Language"
                                    options={languageOptions}
                                />
                                <Select2
                                    value={documentType}
                                    placeholder="Select Document Type"
                                    onChange={setDocumentType}
                                    options={docTypeOptions}
                                />
                                <Select2
                                    value={tone}
                                    placeholder="Select Tone"
                                    onChange={setTone}
                                    options={toneOptions}
                                />
                            </div>
                            <div>
                                <Input.TextArea
                                    placeholder="Document Brief"
                                    rows={4}
                                    required
                                    value={documentBrief}
                                    onChange={(e) => setDocumentBrief(e.target.value)}
                                    className="textarea_class"
                                />
                            </div>
                        </div>

                        <div className="footer">
                            <Button
                                key="submit"
                                type="primary"
                                onClick={handleOk}
                                disabled={isButtonDisabled}
                                style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                            >
                                Create
                            </Button>
                        </div>
                    </>
                )
                }
            </Modal>

            <KeywordModal openKeword={openKeword} setOpenKeyword={setOpenKeyword} setDocModalOpen={setDocModalOpen}
                keywordOptions={keywordsOptions} keyword={keyword} setKeyword={setKeyword} />
        </div>
    );
};

export default CreateDocumentModal;
