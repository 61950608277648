import { Modal } from "antd";
import { useState } from "react";
import right from "../../assets/Chevron_Right.svg";
import left from "../../assets/Chevron_Left_Light.svg";

const SerpModal = ({ open, serpList, setOpenSerpModal }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const handleCancel = () => {
        setOpenSerpModal(false);
    };

    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(serpList?.data?.length / itemsPerPage);
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const paginatedData = serpList?.data?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    ) || [];

    return (
        <Modal open={open} width={917} footer={null} onCancel={handleCancel}>
            <div className="serpTableContainer">
                <div className="serpTableParent">
                    <div className="serpHeading">SERP</div>
                    <div className="serpTableTopWidth">
                        <div className="serpTableHidingFlex">
                            <div className="serph1">Word no.</div>
                            <div className="serph2">Outbound links</div>
                            <div className="serph3">Semantic keyword</div>
                            <div className="serph4">Title score</div>
                            <div className="serph5">SEO score</div>
                        </div>
                    </div>
                    {paginatedData.length > 0 ? (
                        paginatedData.map((item) => {
                            const [id, serpData] = Object.entries(item)[0];

                            return (
                                <div className="serpLine1Flex" key={id}>
                                    <div className="serpLine1ParagraphFlex">
                                        <div className="serpLineNmbr">{id}</div>
                                        <div className="serpParagrph">
                                            {serpData.title}
                                            <div className="serpPargrphLink">
                                                <a
                                                    href={serpData.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {serpData.url}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="serpTablenmbrsFlex">
                                        <div className="wordNo">{serpData.word_count}</div>
                                        <div className="outboundLinks">{serpData.outbound_links_count}</div>
                                        <div className="semanticKeywords">{serpData.keywords_count}</div>
                                        <div className="titleScore">{serpData.Title_score}</div>
                                        <div className="seoScore">{serpData.SEO_score}</div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div>No SERP data available</div>
                    )}
                </div>
                <div className="serpTableFooter">

                    <div className="serpFooterImgs">
                        <div className="paginationInfo">
                            Page {currentPage} of {Math.ceil(serpList?.data?.length / itemsPerPage) || 1}
                        </div>

                        <img src={left} alt="Previous" onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            className="serpFooterLeftImg" />



                        <img src={right} alt="Next"
                            onClick={handleNextPage}
                            disabled={currentPage === Math.ceil(serpList?.data?.length / itemsPerPage)}
                            className="serpFooterRightImg" />
                    </div>

                </div>
            </div>
        </Modal>
    );
};

export default SerpModal;
