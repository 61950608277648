import Sidebar from "../Sidebar";
import Drawer from "../Drawer"

import React from "react";
import useStore from "../../Store";

const Layout = ({ children }) => {
    const { setDrawerOpener } = useStore();

    const handleClose = () => {
        setDrawerOpener(false)
    }
    return (
        <div className="main_container">
            <div className="side_bar">
                <Sidebar />
                <Drawer />
            </div>
            <div className="content-container-main-dashboard" onClickCapture={handleClose}>
                {children}
            </div>
        </div>
    );
}

export default Layout