import React, { useEffect, useState } from "react";
import "./custom.css";
import ProjectsPage from "../../pages/ProjectsPage";
import CreateProjectModal from "../projects/CreateProjectModal";
import useStore from "../../Store";
const DashboardSplash = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [screen1, setScreen1] = useState();
  const [screen2, setScreen2] = useState();
  const [dashboard] = useState(true);
  const { storeProjects, setDrawerOpener } = useStore();

  const showModal = () => {
    setIsModalOpen(true);
    setIsOpen(false);
  };
  useEffect(() => {
    if (storeProjects && storeProjects.length > 0) {
      setScreen2(true)
    }else{
      setScreen1(true)
    }
    setDrawerOpener(false)
  }, []);

  return (
    <div className="main_container">


      {screen1 && (
        <div className="content_container_splash">
          <div className="img_round_main_dashboard">
            <h1 className="forgetHead_text">Welcome to Breevy</h1>
            <p>
              Get started by creating your first project and unlocking the full
              potential of our SEO system.
            </p>
            <button
              className="create_project"
              onClick={showModal}
            >
              Create a New Project
            </button>
          </div>
        </div>
      )}


      {screen2 &&
        <ProjectsPage />
      }




      <CreateProjectModal open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isDashboard={dashboard} setScreen1={setScreen1} setScreen2={setScreen2} />

    </div >
  );
};

export default DashboardSplash;