import { getColorForValue } from "../../utils/utils";

const KeywordsListCard = ({ name, kd, vol, keywordPresent, onSelect }) => {
    let check =  name.toLowerCase()
    const isSelected = keywordPresent.includes(check);
    const kdColor = getColorForValue(kd);

    return (
        <div className="addKeywordsRow">
            <div className="keyWordFleximg">
                <div className="custom-checkbox">
                    <input
                        type="checkbox"
                        id={`checkbox-${name}`}
                        checked={isSelected}
                        onChange={() => onSelect(name)}
                    />
                    <label htmlFor={`checkbox-${name}`}></label>
                </div>
                <div className="addKeywordText1">{name}</div>
            </div>
            <div className="addKeyWordnmbrwidth">
                <div className="addKeyWordnmbrcolor" style={{ backgroundColor: `${kdColor}` }}>
                    {kd}
                </div>
            </div>
            <div className="addKeyWordvolwidth">{vol}</div>
        </div>
    );
}

export default KeywordsListCard;
