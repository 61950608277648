import React from "react";
import AuthSidebar from "../components/auth/signup/RegisterSidebar";
import { Outlet } from "react-router-dom";
import AuthFooter from "../components/auth/AuthFooter"; // Import the new component

const Dashboard = () => {
  return (
    <div className="maindashboardparent">
      <AuthSidebar />
      <div className="content-container-main_new">
        <div className="content-container_new">
          <Outlet />
        </div>
        <AuthFooter />
      </div>
    </div>
  );
};

export default Dashboard;
