const SerpCard = ({ id, title, url }) => {
    return (
        <>
            <div className="serpLine1ParagraphFlexList">
                <div className="serpLineNmbr">
                    {id}
                </div>
                <div className="serpParagrph">
                    {title}
                    <div className="serpPargrphLink">
                        <a href={url} target="_blank" >{url} </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SerpCard