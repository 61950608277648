
import vector from ".././assets/vector.svg";
import tickIcon from ".././assets/tick.svg";
import { useEffect, useState, useRef } from "react";
import api from "../utils/api";
import { useParams } from "react-router-dom";
const TargetAudience = ({ target }) => {

    const [open, setOpen] = useState(false);
    const [selectedAudience, setSelectedAudience] = useState(0);
    const [seletedId, setSelectedId] = useState(null);
    let { id } = useParams();

    const [audiences, setAudiences] = useState([]);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const getTargetAudience = async () => {
            try {
                const response = await api.get(
                    "/target-audiences/",
                );
                setAudiences(response.data)
            } catch (err) {
                console.error("Error: ", err);
            }
        };

        getTargetAudience();
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);
    useEffect(() => {
        setSelectedAudience(target || "Target audience")
    }, []);




    const updateTargetAudience = async () => {
        try {
            await api.put(
                `/projects/${id}/`,
                { target_audiences: [seletedId] },
                
            );

        } catch (err) {
            console.error("Error: ", err);
        }
    };

    const toggleActive = (id) => {
        setSelectedId(id)
        setAudiences((prevAudiences) =>
            prevAudiences.map((audience) => {
                if (audience.id === id) {
                    setSelectedAudience(audience.title);
                    return { ...audience, isActive: true };
                }
                return { ...audience, isActive: false };
            })
        );
        updateTargetAudience()
        setOpen(false)

    };

    const handleClick = () => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    };

    return (
        <>
            <div className="div-2" onClick={handleClick} ref={dropdownRef}>
                <strong>{selectedAudience}</strong>
                <img className="div-2-img-1" src={vector} alt="" />


                {open &&
                    <div className="dropdown" >
                        <div className="dropdown-div1">
                            <div className="dropdown-h1">Target audience</div>
                            <div className="dropdown-p1">
                                Defining the correct target audience enhances AI's ability to <br />
                                generate superior content.
                            </div>
                        </div>

                        {audiences.map(({ id, title, description, age_group, isActive }) => (
                            <div key={id} className={isActive ? 'Active-div' : 'dropdown-div1'} onClick={() => toggleActive(id)}>
                                <div className="active-flex">
                                    <div className="dropdown-h2">{title}</div>
                                    {isActive && <img src={tickIcon} alt="Tick" />}
                                </div>
                                <div className="dropdown-p2">
                                    {age_group}<br />
                                    {description}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </>
    );
}
export default TargetAudience;