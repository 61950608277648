import { useEffect, useState } from "react"
import Layout from "../Layout/Layout"
import "./profile.css"
import useStore from "../../Store"
import ChangePasswordModal from "./ChangePasswordModal"
import camera from "../../assets/cemera-profile.svg"
import trend from "../../assets/trend-left.svg"
import trendDown from "../../assets/trend-right.svg"
import defaultProfile from "../../assets/default_user.svg"
import pencil from "../../assets/edit-profile.svg"
import { toast } from "react-toastify"
import api from "../../utils/api"

const Profile = () => {
    const { user, updateUser } = useStore();
    const { setDrawerOpener } = useStore()
    const [openEdit, setOpenEdit] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [defaultName, setDefaultName] = useState(user.name)
    const [name, setName] = useState(defaultName)
    const [profileImage, setProfileImage] = useState(null);
    const [wordsUsed, setWordsUsed] = useState(0);
    const [imagesUsed, setImagesUsed] = useState(0);
    const [increseWords, setIncreaseWords] = useState(0);
    const [descreaseImages, setDecreaseImages] = useState(0);
    const percentageUsed = (wordsUsed / 3000) * 100;
    const imagePercentage = (imagesUsed / 500) * 100;
    const [profile, setProfile] = useState(user.profile_image);

    const handleOpen = () => {
        setChangePassword(true)
    }
    useEffect(() => {
        const getUsage = async () => {
            try {

                const response = await api.get(
                    "/my-usage/",
                );

                setWordsUsed(response.data.used_words_count)
                setImagesUsed(response.data.used_images_count)
                setIncreaseWords(response.data.percentage_increase_words)
                setDecreaseImages(response.data.percentage_decrease_images)

            } catch (err) {
                console.error("Failed to fetch usage data", err);
                // toast.error("Failed to fetch usage data");
            }
        }
        setDrawerOpener(false)
        getUsage();

    }, []);

    const handleName = (e) => {
        const name = e.target.value
        setName(name)

    }
    const capitalizeFirstLetter = (string) => {
        return defaultName.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

    }
    const handleCameraClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/jpeg, image/jpg';
        
        input.onchange = (event) => {
            const file = event.target.files[0];
            
            if (file) {
                if (file.size > 5 * 1024 * 1024) {
                    toast.error("File size must be less than 5 MB");
                    return;
                }
    
                setProfileImage(file);
    
                const reader = new FileReader();
                reader.onloadend = () => {
                    setProfile(reader.result);
                };
                reader.readAsDataURL(file);
            }
        };
        
        input.click();
    };
    



    const handleSave = async () => {
        if (!name || name.trim() === "") {
            toast.error("Please enter a name. Name cannot be blank.");
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        if (profileImage) {
            formData.append('profile_image', profileImage);
        }

        try {
            const response = await api.put(`user/${user.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setDefaultName(response.data.name);
            updateUser(response.data)
            toast.success("Profile updated successfully.");
            setOpenEdit(false)
        } catch (err) {
            console.error("Fetching data failed:", err);
            toast.error("Failed to update profile. Please try again.");
        }
    };




    const handleEdit = () => {
        setOpenEdit(!openEdit)
        if (openEdit) {
            setProfile(user.profile_image)
            setProfileImage(null)
        }
    }
    return (

        <Layout>
            <div className="myaccountContainer">
                <div className="accountHead">
                    <div className="accountText">
                        Account overviews
                    </div>
                </div>
                <div className="accountBodyflex">
                    <div className="accountBody">
                        <div className="accountHeading">
                            My Account
                        </div>
                        <div className="accountInfo">
                            <div className="accountInfoEdit">
                                <div className="accountInfoEditFlex">
                                    <div className="accountLeftFlex">
                                        <div className="accountProfilePicCam">
                                        {profile?  
                                            <img src={profile} alt="Profile" className="accProfilePicChange" /> :
                                            <img src={defaultProfile} alt="Profile" className="accProfilePicChange" /> }
                                            <div className="profileCamera" onClick={handleCameraClick}>
                                                {openEdit && <img src={camera} alt="Camera" />}
                                            </div>
                                        </div>

                                        <div className="profileOwnerName">
                                            {capitalizeFirstLetter(defaultName) || "name"}
                                            <div className="profileOwnerPlanName">
                                                {/* Plan name */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accountRightFlex">
                                        <div className="profileEditButton"  onClick={handleEdit}>
                                            <img src={pencil} alt="" />
                                        </div>
                                        <div className="editaccountinfoText"  onClick={handleEdit}>
                                            EDIT ACCOUNT INFO
                                        </div>

                                        {/* <div className="subProFlex">
                                            <div className="">
                                                <img src={sub} alt="" />
                                            </div>
                                            <div className="subProText">
                                                Go Pro
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {openEdit && <div className="accountDetails">
                                <div className="accountNameEmailFlex">
                                    <div className="accountNameFlex">
                                        <div className="accountNameEdit">
                                            Name
                                        </div>
                                        <div className="accountInputTextBorder">
                                            <input className="accountInputText" name="name" type="text" placeholder="Joe smith" value={name || null} onChange={handleName} />
                                        </div>
                                    </div>
                                    <div className="accountEmailFlex">
                                        <div className="accountEmailEdit">
                                            Email
                                        </div>
                                        <div className="accountEmailInputTextBorder">
                                            <input className="accountEmailInputText not-allowed" value={user.email || null} type="email" disabled />
                                        </div>
                                    </div>
                                </div>

                                <div class="accountPassChngbtnsFlex">
                                    <button
                                        className="accountPassSavebtn"
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button>
                                    <button class="accountPassChngbtn" onClick={handleOpen}>
                                        Change password
                                    </button>
                                </div>
                            </div>
                            }
                        </div>

                        <div className="accountUsageText">
                            My Usage
                        </div>
                        <div className="accountUsageDetailsFlex">
                            <div className="accountUsageCardBgclr">
                                <div className="accountUsageTopFlex">
                                    <div className="accountUsageTextFlex">
                                        <div className="accountUsageTextWord">
                                            Word used
                                        </div>
                                        <div className="percentageFlex">
                                            <img src={trend} alt="" />
                                            <div className="textTrend">
                                                {increseWords}% Increase from Last week
                                            </div>
                                        </div>
                                    </div>
                                    <div className="usageNmbrsFlex">
                                        <div className="usageNmbrInc">
                                            {wordsUsed}/
                                        </div>
                                        <div className="usageNmbrTotal">
                                            3000
                                        </div>
                                    </div>
                                </div>
                                <div className="progressBarProfile">

                                    <div
                                        className="progressBarFill"
                                        style={{ width: `${percentageUsed}%` }}
                                    ></div>
                                </div>


                                {/* <div class="buyWordsbtnFlex">
                                    <button class="buyWordsbtn">
                                        Buy Words
                                    </button>
                                </div> */}
                            </div>
                            <div className="accountUsageCardBgclr">
                                <div className="accountUsageTopFlex">
                                    <div className="accountUsageTextFlex">
                                        <div className="accountUsageTextWord">
                                            Image prompt used
                                        </div>
                                        <div className="percentageFlex">
                                            <img src={trendDown} alt="" />
                                            <div className="textTrend">
                                                {descreaseImages}% Decrease from Last week
                                            </div>
                                        </div>
                                    </div>
                                    <div className="usageNmbrsFlex">
                                        <div className="usageNmbrInc">
                                            {imagesUsed}/
                                        </div>
                                        <div className="usageNmbrTotal">
                                            500
                                        </div>
                                    </div>
                                </div>
                                <div className="progressBarProfile">
                                    <div
                                        className="progressBarFillImages"
                                        style={{ width: `${imagePercentage}%` }}
                                    ></div>
                                </div>

                                {/* <div class="buyWordsbtnFlex">
                                    <button class="buyWordsbtn">
                                        Buy Images
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChangePasswordModal open={changePassword} setChangePassword={setChangePassword} />
        </Layout>
    )
}

export default Profile