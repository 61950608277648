import React, { useEffect, useState } from "react";
import './project.css'
import { Link, useParams } from "react-router-dom";
import useStore from "../../Store";
import EditModal from "./EditModal";
import ProjectDocumentsCard from "./ProjectDocumentsCard";
import ProjectNav from "./ProjectNav";
import Layout from "../Layout/Layout"
import ProjectDetailContent from "./ProjectDetailContent";
import projectData from "../../utils/projectData";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";


const ProjectDetails = () => {
    const [docType, setDocType] = useState(null)
    const [aiDoc, setAiDoc] = useState([])
    const { setDrawerOpener } = useStore();
    const [isOpen, setIsOpen] = useState(true);
    const [projectName, setProjectName] = useState('')
    const [documents, setDocuments] = useState([])

    let { id } = useParams();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const [project, setProject] = useState('')
    useEffect(() => {
        setDrawerOpener(false)

        const fetchData = async () => {
            try {
                const data = await projectData(id);
                setProject(data.project)
                setProjectName(data.project.name)
                setDocuments(data.documents)
            } catch (err) {
                navigate("/not-found")
            }
        };

        const fetchAiDoc = async () => {
            try {
                const response = await api.get(
                    `project/${id}/ai-recommended-documents`,
                );
                setAiDoc(response.data?.ai_recommended_documents?.example || []);
            } catch (err) {
                console.error("Error: ", err)
            }
        };

        fetchData();
        fetchAiDoc();

    }, [id]);
    return (
        <Layout>
            <div className="Parent-div">
                <div className="head-nav">

                    <ProjectNav project={project} projectName={projectName} setIsModalOpen={setIsModalOpen} setIsOpen={setIsOpen} logo_url={project.logo_url} projectId={id} />
                    <div className="Content">
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '70%' }}>
                                <div className="rightContent">
                                    <div>
                                        AI recommendation for you
                                    </div>
                                </div>
                                <div className="cards">
                                    {aiDoc.map((doc, index) => (
                                        <ProjectDetailContent key={index} id={id} doc={doc} index={index}  />
                                    ))}
                                </div>
                            </div>
                            <div className="Content-2">
                                <div className="head">
                                    <div className="head-1">Recent documents</div>
                                    {documents.length > 0 &&
                                        <div className="head-2 color_blue"><Link className="color_blue" to={`/project/${project.id}/documents`} > SEE ALL </Link></div>}
                                </div>
                                <div className="scroll-card">
                                    {documents && documents.length > 0 ? (
                                        documents.map((document, index) => (
                                            <ProjectDocumentsCard
                                                projectName={document.project_name}
                                                title={document.name}
                                                seoScore={document.seo_score}
                                                status={document.doc_status}
                                                date={document.created_at}
                                                documentBrief={document.doc_brief}
                                                key={index}
                                                id={document.id}
                                                projectId={document.project}
                                            />

                                        ))
                                    ) : (

                                        <ProjectDocumentsCard noCard={true} />

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <EditModal open={isModalOpen} setIsModalOpen={setIsModalOpen} id={id} setProjectName={setProjectName} projectName={projectName} project={project} />

                </div>

            </div>
        </Layout>
    )
};

export default ProjectDetails;