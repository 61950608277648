
import { useEffect, useState } from 'react';
import chevron from '../../assets/Chevron_Down.svg'
import { toast } from 'react-toastify';
import api from '../../utils/api';
import { useNavigate } from 'react-router-dom';
const ProjectDetailContent = ({ doc, id }) => {

    const [docCard, setDocCard] = useState(null)

    useEffect(() => {
        setDocCard(doc)

    }, [doc]);

    const navigate = useNavigate();
    const HandleStart = async () => {
        try {
            const response = await api.post(
                "/documents/",
                {
                    project: id, main_keyword: docCard.keyword, doc_type: docCard.content_type, tone: docCard.tone, language: "English", doc_brief: "",
                    document_content: {
                        title: "<h1>" + docCard.title + "</h1>"
                    }
                },


            );

            toast.success("Document created successfully");
            navigate(`/document/${response.data.id}/`);

        } catch (err) {
            console.error("Document creation failed:", err);
            toast.error(`${err?.response?.data?.main_keyword[0]}`);
        }
    }

    return (
        <div className="card-1">
            <div class="ribbon">
                <span class="ribbon1"><span>Blog</span></span>
            </div>
            <div className="main-keyword-text">{docCard?.keyword ? docCard.keyword : <span className="block pulsate"></span>}</div>
            <div className="article-1"> {docCard?.title ? docCard.title : <span className="block-2 pulsate"></span>}</div>
            <div className="content-1">
                <div className="content-div-1">{docCard?.title ? `create with AI` : ""}</div>
                <div className="content-div-2" onClick={HandleStart}>
                    {docCard?.title ? `Start from scratch` : <span className="block-3 pulsate"></span>}
                    {docCard?.title && <img
                        className="scratchImg"
                        src={chevron}
                        alt=""
                    />}
                </div>
            </div>
        </div>

    )
}
export default ProjectDetailContent