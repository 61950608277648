import api from "./api";

const fetchTitles = async (id) => {
    try {
        const response = await api.get(`/document/${id}/generate-titles/`);
        if (!response || !response.data || !Array.isArray(response.data.titles)) {
            console.warn("Titles data not found or invalid.");
            return [];
        }
        const fetchedTitles = response.data.titles.map((titleObj, index) => {
            const text = Object.keys(titleObj)[0];
            const count = titleObj[text];
            return { id: index + 1, text, count };
        });

        return fetchedTitles;
    } catch (error) {
        console.error("Error fetching titles:", error);
        return [];
    }
};


const fetchSubheading = async (id) => {
    try {
        const response = await api.get(`/document/${id}/generate-subheadings/`);
        let subheadings = response?.data?.text
        const fetchedSubheadings = Object.keys(subheadings).map((text, index) => {
            const headingLevel = subheadings[text];
            return { id: index + 1, text, headingLevel };
        });
        return(fetchedSubheadings);
    } catch (error) {
        console.error("Error fetching subHeadings:", error);
        return [];
    }
};


const fetchIntroduction = async (id) => {
    try {
        const response = await api.get(`/document/${id}/generate-introductions/`);
        if (!response || !response.data || !Array.isArray(response.data.introductions)) {
            console.warn("No valid introductions data found.");
            return [];
        }
        const fetchedIntros = response.data.introductions.map((text, index) => ({
            id: index + 1,
            text
        }));
        return fetchedIntros;
    } catch (error) {
        console.error("Error fetching Intros:", error);
        return [];
    }
};

const fetchFaqs = async (id) => {
    try {
        const response = await api.get(`/document/${id}/generate-faqs/`);
        if (!response || !response.data || !response.data.faqs || typeof response.data.faqs !== 'object') {
            console.warn("No valid FAQs data found.");
            return [];
        }
        const fetchedFaqs = Object.entries(response.data.faqs).map((faq, index) => {
            const [question, answer] = faq;
            return { id: index + 1, question, answer };
        });
        return fetchedFaqs;
    } catch (error) {
        console.error("Error fetching faqs:", error);
        return [];
    }
};

const fetchKeypoints = async (id) => {
    try {
        const response = await api.get(`/document/${id}/generate-keypoints/`);
        if (!response || !response.data || !response.data.keypoints || typeof response.data.keypoints !== 'object') {
            console.warn("Keypoints data not found or invalid.");
            return [];
        }
        const fetchedKeyPoints = Object.entries(response.data.keypoints).map((keypoint, index) => {
            const [title, description] = keypoint;  
            return { id: index + 1, title, description }; 
        });
        return fetchedKeyPoints;
    } catch (error) {
        console.error("Error fetching keypoints:", error);
        return [];
    }
};


const fetchSeoScore = async (id) => {
    try {
        const response = await api.get(`/document/${id}/calculate-seo-score/`);
        if (!response || !response.data || typeof response.data.seo_score === 'undefined') {
            console.warn("SEO score not found or invalid.");
            return null;
        }
        return response.data.seo_score;
    } catch (error) {
        console.error("Error fetching seo score:", error);
        return null;
    }
};



const fetchSummary = async (id) => {
    try {
        const response = await api.post(`/document/${id}/generate-summary/`, {
            title: 'dummy Title',
          });  
        if (!response || !response.data || typeof response.data.summaries === 'undefined') {
            console.warn("Summary text not found or invalid.");
            return null;
        }
        return response.data.summaries;
    } catch (error) {
        console.error("Error fetching summary:", error);
        return null;
    }
};


const fetchImages = async(id) => {
    try {

        const response = await api.get(`/document/${id}/search-images/`);
        const fetchedImages = response.data?.images_data;
        return (fetchedImages)
    } catch (error) {
        console.error("Error fetching summary:", error);
        return [];
    }
}

const fetchSerpData = async (id) => {
    try {
        const response = await api.get(`/document/${id}/serp/`);
        return(response.data?.serp)
    } catch (error) {
        console.error("Error fetching seo score:", error);
        return [];
    }
};


const calculateAverageSeoScore = (serpData) => {
    if (!serpData || !serpData.data || !Array.isArray(serpData.data) || serpData.data.length === 0) {
        console.warn("Invalid or empty serpData.");
        return null;
    }
    const seoScores = serpData.data.slice(0, 5).map(item => {
        const seoScore = Object.values(item)[0]?.SEO_score;
        if (typeof seoScore !== 'number' || isNaN(seoScore)) {
            console.warn("Invalid SEO score found:", seoScore);
            return 0; 
        }
        return seoScore;
    });
    const totalSeoScore = seoScores.reduce((sum, score) => sum + score, 0);
    const averageSeoScore = totalSeoScore / seoScores.length;
    return averageSeoScore;
};

export {  fetchTitles, fetchSubheading, fetchIntroduction, fetchFaqs, fetchKeypoints,  fetchSeoScore,fetchSummary,fetchSerpData, fetchImages, calculateAverageSeoScore}