import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Input, Modal, Select, Typography } from "antd";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import fetchProjects from "../../utils/fetchProjects";
import useStore from "../../Store"

const CreateProjectModal = ({ open, setIsModalOpen, isDashboard, setScreen1, setScreen2 }) => {
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [url, setUrl] = useState("");
    const [location, setLocation] = useState('');
    const [subLocation, setSubLocation] = useState(''); // State for sub-location

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const { setProjects, clearProjects, locations } = useStore();
    const token = Cookies.get("access_token");
    const { Option } = Select;
    const { Text } = Typography;
    const [visible, setVisible] = useState(true)
    const [isChecked, setIsChecked] = useState(false);
    const [loader, setLoader] = useState(false);


    const handleOk = async () => {
        setLoader(true)
        try {
            if (!token) {
                throw new Error("No access token found");
            }

            const response = await api.post(
                "/projects/",
                { name: name, url: url, location: location, sub_location: subLocation },
            );
            const projectId = response.data.project.id;
            toast.success("Project created successfully");
            handleCancel();
            const projects = await fetchProjects();
            setName('');
            clearProjects();
            setProjects(projects);
            setLoader(false)
            navigate(`/project/${projectId}`);
        } catch (err) {
            console.error("Project creation failed:", err);
            toast.error("Error creating project. Please try again.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setName('');
        setUrl('');
        setLocation('');
        setIsButtonDisabled(true);
        setButtonColor('grey');
    };

    const isValidURL = (url) => {
        const regex = /^https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/?$/;
        return regex.test(url);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        validateForm(newName, url, location, subLocation);
    };

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        if (name && isValidURL(newUrl) && location && subLocation) {
            setIsButtonDisabled(false);
            setButtonColor('black');
        } else {
            setIsButtonDisabled(true);
            setButtonColor('grey');
        }
    };


    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleHide = () => {
        if (isChecked) {
            localStorage.setItem("project-consent", true);
        }
        setVisible(false)
    }

    const handleSubLocationChange = (value) => {
        setSubLocation(value);
        validateForm(name, url, location, value);
    };

    const validateForm = (name, url, location, subLocation) => {
        if (name && isValidURL(url) && location && (subLocation || !locations.find(loc => loc.id === location).sublocations.length)) {
            setIsButtonDisabled(false);
            setButtonColor("black");
        } else {
            setIsButtonDisabled(true);
            setButtonColor("grey");
        }
    };


    useEffect(() => {
        const projectConsent = localStorage.getItem("project-consent");
        if (projectConsent) {
            setVisible(false);
        }
    }, []);
    const selectedCountry = locations.find(loc => loc.id === location);

    return (<>
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={loader? false: handleCancel}
                width={loader? 180 : 700}
                centered
                className="padding-modal"
                closable={!loader}
                footer={loader? null : [
                    <div className="padding-modal" key="footer">
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            disabled={isButtonDisabled}
                            style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                        >
                            Create Project
                        </Button>
                    </div>,
                ]}
            >

                {loader ? (
                    <div className="centered-loader">
                        <div className="loader">
                            <div className="circle-loader">
                                {[...Array(8)].map((_, index) => (
                                    <span key={index} className="dot"></span>
                                ))}
                            </div>
                        </div>
                    </div>
                ):(
                <div className="padding-modal">
                    <Typography.Title level={2}>Create a new project</Typography.Title>
                    <Text strong>*Name</Text>
                    <div className="positioning">
                        <Input
                            placeholder="Project Name"
                            value={name}
                            onChange={handleNameChange}
                            type="text"
                            required
                            style={{ width: 400, marginTop: 5, marginBottom: 15 }}
                        />


                        {visible && <div className="showMsgPositionAdjust">
                            <div className="random-popover-content-right">
                                <div>
                                    <div className="firstProjectText">
                                        Hooray! your first project!
                                    </div>
                                    <div className="firstProjectPara">

                                        Simply enter your website URL and let our AI scan and analyze it to enhance your results. Gain
                                        insights into your target audience, refine your tone of voice, and understand your competition
                                        better for optimal outcomes
                                    </div>
                                </div>
                                <div className="showMsgFotterFlex">
                                    <div className="showMsgCheckBox">
                                        <input
                                            type="checkbox"
                                            defaultChecked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        Don't show this message again
                                    </div>
                                    <div className="showMsgCheckBoxButton" onClick={handleHide}>
                                        Ok, Got it
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <Text strong level={5}>*URL</Text>
                    <div>
                        <Input
                            placeholder="URL"
                            value={url}
                            onChange={handleUrlChange}
                            type="text"
                            required
                            style={{ width: 400, marginTop: 5 }}
                        />
                    </div>
                    {url && !isValidURL(url) &&
                        <>
                            <div className="warn">
                                <em className="hint">
                                    "You must enter the main domain of the site" and any URL that is not the main domain is not approved for entry
                                </em> <br />
                            </div>
                        </>}
                    <Select
                        showSearch
                        style={{ width: 200, marginTop: 15 }}
                        placeholder="Select location"
                        value={location || undefined}
                        onChange={(value) => {
                            setLocation(value);
                            validateForm(name, url, value);
                        }}
                    >
                        {locations.map((location) => (
                            <Option key={location.id} value={location.id}>
                                {location.country_name}
                            </Option>
                        ))}
                    </Select>

                    <div>

                        {selectedCountry && selectedCountry.sublocations.length > 0 && (
                            <Select
                                showSearch
                                style={{ width: 200, marginTop: 15 }}
                                placeholder="Select sub-location"
                                value={subLocation || undefined}
                                onChange={handleSubLocationChange}
                            >
                                {selectedCountry.sublocations.map(subLocation => (
                                    <Option key={subLocation.id} value={subLocation.id}>
                                        {subLocation.name}
                                    </Option>
                                ))}
                            </Select>
                        )}
                    </div>

                </div>
                )}
            </Modal>
        </div>
    </>
    );
};

export default CreateProjectModal