import { Input } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackArrow from "../../assets/backarrow.png";
import React, { useState } from "react";
import api from "../../utils/api";
import { toast } from "react-toastify";

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const isPasswordMatch = password && confirmPassword && password === confirmPassword;
    const { reset_token } = useParams();
    const navigate = useNavigate();
    const handleResetPassword = async () => {
        try {
            await api.post(
                `/password-reset/${reset_token}/`,
                { new_password: password, confirm_password: confirmPassword }

            );
            toast.success("Password Reset successfully");
            navigate("/login");


        } catch (err) {

            console.error(err);
            if (err.response && err.response.data && err.response.data.new_password2) {
                const errorMessages = Object.values(err.response.data.new_password2).flat();
                errorMessages.forEach(message => toast.error(message));
                toast.error(err.response.data.errors);
            } else if (err.response && err.response.data && err.response.data.errors) {
                toast.error(err.response.data.errors);
            } else
                toast.error("Failed to Reset Password.");
        }
    }

    return (
        <>
            <div className="">
                <Link to="/forgot-password">
                    <div className="back-arrow">
                        <img src={BackArrow} alt="Back" />
                    </div>
                </Link>
                <h1>Create New Password</h1>
                <p>Enter a strong and secure password for your account.</p>

                <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                />
                <div className="margin-top">
                    <Input.Password
                        placeholder="Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="margin-top">
                    <button
                        className="createaccountbtn"
                        style={{
                            backgroundColor: isPasswordMatch ? "black" : "grey",
                            color: "white",
                            cursor: isPasswordMatch ? "pointer" : "not-allowed"
                        }}
                        disabled={!isPasswordMatch}
                        onClick={handleResetPassword}
                    >
                        Reset Password
                    </button>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;
