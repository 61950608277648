import Logo from "../../assets/logo.png";
import Icon from "../../assets/fi_9356210.svg";
import React, { useState } from "react";
import CreateDocumentModal from "../Documents/CreateDocumentModal";
import TargetAudience from "../TargetAudience";

const ProjectNav = ({ project , projectName, setIsOpen, setIsModalOpen , logo_url, projectId}) => {
    const [docModalOpen, setDocModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
        setIsOpen(false);
    };

    const showDocModal = () => {
        setDocModalOpen(true);
    };

    return (
        <>
            <div className="main-div">
                <div className="div-1">
                    <img className="div-1-img-1" src={logo_url || Logo}  alt="Home" />
                    <div className="project-name">
                        {projectName}
                    </div>
                    <img className="div-1-img-2" src={Icon} alt="Edit" onClick={showModal} />
                </div>

                <div className="main-div-2">
                   <TargetAudience />
                    <div className="newDoc" onClick={showDocModal}>New Document</div>
                </div>
            </div>
            <CreateDocumentModal open={docModalOpen} setDocModalOpen={setDocModalOpen} id={projectId}/>
        </>
    );
};

export default ProjectNav;
