import React, { useState, useEffect } from "react";
import { Input, Modal, Typography } from "antd";
import { Button } from "@mui/material";
import Openeyeimg from "../../assets/open_Eye.png";
import Closedeyeimg from "../../assets/closed_Eye.png";
import api from "../../utils/api";
import { toast } from "react-toastify";

const ChangePasswordModal = ({ open, setChangePassword }) => {
    const { Text } = Typography;
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const [errorMessage, setErrorMessage] = useState("");

    const handleCancel = () => {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setChangePassword(false);
    };

    const handleCurrentPassword = (e) => {
        setCurrentPassword(e.target.value);
    };

    const handleNewPassword = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    };
    const handleOK = async () => {
        try {
            await api.post(
                "/change-password/",
                { current_password: currentPassword, new_password: newPassword, confirm_password: confirmPassword }
            );

            toast.success("Password updated successfully.");
            handleCancel();
        } catch (err) {
            console.error("Fetching data failed:", err);
            if (err?.response?.data?.current_password) {
                toast.error(err.response.data.current_password[0]);

            } else {
                toast.error("Failed to update password. Please try again.");
            }
        }
    };


    useEffect(() => {
        const isEmpty = !currentPassword || !newPassword || !confirmPassword;
        const doPasswordsMatch = newPassword === confirmPassword;

        setIsButtonDisabled(isEmpty || !doPasswordsMatch);
        setButtonColor(isEmpty || !doPasswordsMatch ? 'grey' : 'black');

        if (!doPasswordsMatch && confirmPassword) {
            setErrorMessage("Passwords must be identical.");
        } else {
            setErrorMessage("");
        }
    }, [currentPassword, newPassword, confirmPassword]);

    return (
        <Modal
            open={open}
            width={700}
            onCancel={handleCancel}
            className="padding-modal"
            footer={[
                <div className="padding-modal password-modal-footer" key="footer">
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOK}
                        disabled={isButtonDisabled}
                        style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                    >
                        Save
                    </Button>
                </div>,
            ]}
        >
            <div className="padding-modal">
                <Typography.Title level={2}>Change Password</Typography.Title>

                <div className="margin-passwords">
                    <Text strong className="pass-label">Current Password</Text>
                    <Input.Password
                        placeholder="Current Password"
                        className="c-pass"
                        required
                        onChange={handleCurrentPassword}
                        value={currentPassword}
                        iconRender={(visible) =>
                            visible ? (
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={Openeyeimg}
                                    alt="open"
                                />
                            ) : (
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={Closedeyeimg}
                                    alt="closed"
                                />
                            )
                        }
                    />
                </div>

                <div className="margin-passwords">
                    <Text strong className="pass-label">New Password</Text>
                    <Input.Password
                        placeholder="New Password"
                        className="n-pass"
                        required
                        onChange={handleNewPassword}
                        value={newPassword}
                        iconRender={(visible) =>
                            visible ? (
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={Openeyeimg}
                                    alt="open"
                                />
                            ) : (
                                <img
                                    style={{ cursor: "pointer" }}
                                    src={Closedeyeimg}
                                    alt="closed"
                                />
                            )
                        }
                    />
                </div>

                {newPassword && newPassword.length < 8 && <Text type="danger">Password must be at least 8 characters long.</Text>}

                <div className="margin-passwords">
                    <Text strong className="pass-label">Confirm Password</Text>
                    <div>
                        <Input.Password
                            placeholder="Confirm Password"
                            className="confirm-pass"
                            value={confirmPassword}
                            onChange={handleConfirmPassword}
                            required
                            iconRender={(visible) =>
                                visible ? (
                                    <img
                                        style={{ cursor: "pointer" }}
                                        src={Openeyeimg}
                                        alt="open"
                                    />
                                ) : (
                                    <img
                                        style={{ cursor: "pointer" }}
                                        src={Closedeyeimg}
                                        alt="closed"
                                    />
                                )
                            }
                        />
                    </div>
                    {errorMessage && <Text type="danger">{errorMessage}</Text>}
                </div>
            </div>
        </Modal>
    );
};

export default ChangePasswordModal;