import headlineIcon from '../../assets/fi_4662543.svg';
import subheadingIcon from '../../assets/fi_4662543 (1).svg';
import introIcon from '../../assets/List_Add.svg';
import faqIcon from '../../assets/fi_3524344.svg';
import keyPointsIcon from '../../assets/List_Checklist.svg';
import imageIcon from '../../assets/fi_9261193.svg';
import summarizeIcon from '../../assets/List_Check.svg';

const DocumentSection = ({ handleDisplay, handleSubheading, handleIntro, handleImage, handleKeyPoint, handleSummary, handleFaq, title }) => {
    return (
        <>
            <div className="typoflex">
                <div className="typodiv-1" onClick={handleDisplay}>
                    <div className="typoContent">
                        <img className="typoImg" src={headlineIcon} alt="" />
                        <div>Headline</div>
                    </div>
                </div>
                <div className={`typodiv-1 ${!title? "disabled-description" : " "}`} onClick={title? handleSubheading: null}>
                    <div className="typoContent" >
                        <img className="typoImgsub" src={subheadingIcon} alt="" />
                        <div>Subheading</div>
                    </div>
                </div>
                <div className={`typodiv-1 ${!title? "disabled-description" : " "}`} onClick={title? handleIntro : null}>
                    <div className="typoContent" >
                        <img className="typoImgAdd" src={introIcon} alt="" />
                        <div>Introduction</div>
                    </div>
                </div>
            </div>

            <div className="typoflex">
                <div className={`typodiv-1 ${!title? "disabled-description" : " "}`} onClick={title? handleFaq : null}>
                    <div className="typoContent">
                        <img className="typoImg" src={faqIcon} alt="" />
                        <div>FAQ</div>
                    </div>
                </div>
                <div className={`typodiv-1 ${!title? "disabled-description" : " "}`} onClick={title? handleKeyPoint: null}>
                    <div className="typoContent" >
                        <img className="typoImg" src={keyPointsIcon} alt="" />
                        <div>Key points</div>
                    </div>
                </div>
                <div className={`typodiv-1 ${!title? "disabled-description" : " "}`} onClick={title? handleImage : null }>
                    <div className="typoContent">
                        <img className="typoImgAdd" src={imageIcon} alt="" />
                        <div>Image</div>
                    </div>
                </div>
            </div>
            <div className="typoflex">
                <div className={`typodiv-1 ${!title? "disabled-description" : " "}`} onClick={title? handleSummary: null}>
                    <div className="typoContent">
                        <img className="typoImg" src={summarizeIcon} alt="" />
                        <div>Summarize</div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default DocumentSection