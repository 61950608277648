import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const user = Cookies.get("access_token");
  return user ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
