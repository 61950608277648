import Cookies from "js-cookie";
import api from "./api";

const fetchProjects = async () => {
    try {
        const token = Cookies.get("access_token");

        if (!token) {
            throw new Error("No access token found");
        }

        const response = await api.get("/projects/", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching projects:", error);
        throw error;
    }
};

export default fetchProjects;
