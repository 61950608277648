import api from "./api";

const ProjectData = async (id) => {
    try {
        const response = await api.get(
            `/projects/${id}`,
        );
    
        return response.data;
    } catch (err) {
        if (err.response.data.detail) {
            return err.response.data.detail
        }
    }
};

export default ProjectData;