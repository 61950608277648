import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { StatusEnum } from "../../utils/utils";
import tick from "../../assets/tick.svg";
import api from "../../utils/api";
import vector from "../../assets/vector.svg";

import { toast } from "react-toastify";

const ProjectDocumentsCard = ({ id, projectName, title, seoScore, status, date, documentBrief, noCard, projectId }) => {
    const [dropdownState, setDropdownState] = useState({});

    const [selectedStatus, setSelectedStatus] = useState(status);
    const dropdownRef = useRef(null);

    const handleStatus = (event) => {
        event.preventDefault();
        setDropdownState(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const statuses = Object.keys(StatusEnum).map(key => ({
        label: StatusEnum[key],
        value: StatusEnum[key],
    }));

    const toggleActive = async (event, value) => {
        event.preventDefault();
        setSelectedStatus(value);
        setDropdownState(prevState => ({
            ...prevState,
            [id]: false
        }));

        try {
            await api.patch(`/documents/${id}/`, {
                doc_status: value
            });
            toast.success("Document saved successfully");
        } catch (err) {
            console.error("Document update failed:", err);
            toast.error("Error updating Document. Please try again.");
        }
    };

    useEffect(() => {
        setSelectedStatus(status);
    }, [status]);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownState(prevState => ({
                    ...prevState,
                    [id]: false // Close dropdown if clicked outside
                }));
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [id]);

    return (
        <div className="cnt-2-div-1">
            {noCard ? (
                <div className="no-card-message">
                    <h5>No Document Available</h5>
                </div>
            ) : (
                <>
                    <Link to={`/document/${id}`}>
                        <div className="cnt-2-1">
                            {projectName && <Link to={`/project/${projectId}`}> {projectName} </Link>}
                        </div>
                        <div className="cnt-2-2">
                            {title && <Link to={`/document/${id}`}> {title} </Link>}
                        </div>
                        <div className="cnt-2-3">
                            {documentBrief !== '' ? `${documentBrief}` : "No description available"}
                        </div>
                        <div className="cnt-2-4">
                            SEO Score
                        </div>
                        <div className="cnt-2-5">
                            {seoScore !== null ? `${seoScore}%` : '- %'}
                        </div>
                        <div className="cnt-2-6">
                            <div className="dropdown-project-status" ref={dropdownRef}>
                                {status && (
                                    <>
                                        <span
                                            className={`project-status ${selectedStatus && selectedStatus.toLowerCase().replace(" ", "-")}`}
                                            onClick={handleStatus}
                                        >
                                            {selectedStatus}

                                        <img  style={{ cursor: "pointer", marginLeft: "5px" }} src={vector} alt="" />
                                        </span>
                                    </>

                                )}

                                {dropdownState[id] && (
                                    <div className="status-project-dropdown">
                                        {statuses.map((allStatus) => (
                                            <div
                                                key={allStatus.value}
                                                className="status-padding"
                                                onClick={(e) => toggleActive(e, allStatus.value)}
                                            >
                                                <div className={`status-flex ${selectedStatus === allStatus.value ? 'non-active' : 'active-status'}`}>
                                                    <div>{allStatus.label}</div>
                                                    {selectedStatus === allStatus.value && <img src={tick} alt="Tick" />}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="cnt-date">
                                {date && date}
                            </div>
                        </div>
                    </Link>
                </>
            )}
        </div>
    );
};

export default ProjectDocumentsCard;
